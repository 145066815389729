<template>
    <v-card>
      <v-alert
        :value="createdSuccess"
        type="success"
        class="my-0"
      >Пользователь изменен</v-alert>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-title
          class="px-4"
        >
          <span class="headline">Изменить настройку</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          class="px-4"
        >
          <v-text-field
            label="пользователь"
            :rules="[v => !!v || 'Заполните поле']"
            v-model="editedItem.user"
          ></v-text-field>
          <v-text-field
            label="имя базы данных"
            :rules="[v => !!v || 'Заполните поле']"
            v-model="editedItem.db"
          ></v-text-field>
          <v-text-field
            label="пароль"
            v-model="editedItem.password"
          ></v-text-field>
          <v-text-field
            label="путь"
            :rules="[v => !!v || 'Заполните поле']"
            v-model="editedItem.host"
          ></v-text-field>
          <v-select
            label="Список магазинов"
            :items="storeList"
            :rules="[v => !!v || 'Заполните поле']"
            item-text="name"
            item-value="id"
            v-model="editedItem.store_id"
            hide-details
            class="mb-4"
          ></v-select>
          <v-autocomplete
            label="Клиент"
            :items="clientsList"
            item-text="name"
            item-value="id"
            v-model="editedItem.client_id"
            :rules="[v => !!v || 'Заполните поле']"
            hide-details
            no-data-text="Не надено"
            clearable
            :search-input.sync="searchClients"
            class="mb-4"
          ></v-autocomplete>
          <v-autocomplete
            label="B24 User"
            :items="userList"
            item-text="name"
            item-value="id"
            v-model="editedItem.user_id"
            :rules="[v => !!v || 'Заполните поле']"
            hide-details
            no-data-text="Не надено"
            clearable
            :search-input.sync="searchUsers"
            class="mb-4"
          ></v-autocomplete>
          <v-autocomplete
            label="Collector Client"
            :items="collectorList"
            item-text="name"
            item-value="id"
            v-model="editedItem.collector_id"
            :rules="[v => !!v || 'Заполните поле']"
            hide-details
            no-data-text="Не надено"
            clearable
            :search-input.sync="searchCollector"
          ></v-autocomplete>
          <v-text-field
            label="ip address"
            :rules="[v => !!v || 'Заполните поле']"
            v-model="editedItem.ip_address"
          ></v-text-field>
          <v-checkbox
            v-model="editedItem.cron_is_active"
            label="CronJob Active"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions
          class="px-4 pb-4"
        >
          <v-btn
            @click.native="cancel()"
          >Отмена</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            @click="submitForm"
          >Сохранить</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        editedItem: {},
        createdSuccess: false,
        storeList:[],
        search: "",
        searchClients: "",
        searchUsers: "",
        searchCollector: "",
        clientsList: [],
        collectorList: [],
        userList: [],
        timerClients: null,
        timerUsers: null,
        timerCollector: null,
      };
    },
    watch: {
      searchClients(val) {
        const findClient = this.clientsList.find((item) => item.name === val);
        if (findClient) return false;

        if (val && val.length >= 3) {
          if (this.timerClients) clearTimeout(this.timerClients);

          this.timerClients = setTimeout(() => {
            const type = "client"
            this.getClients(val, type);
          }, 500);
        } else {
          this.clientsList = [];
        }
      },
      searchUsers(val) {
        const findUser = this.userList.find((item) => item.name === val);
        if (findUser) return false;

        if (val && val.length >= 3) {
          if (this.timerUsers) clearTimeout(this.timerUsers);

          this.timerUsers = setTimeout(() => {
            this.getUsers(val);
          }, 500);
        } else {
          this.userList = [];
        }
      },
      searchCollector(val) {
        const findCollector = this.collectorList.find((item) => item.name === val);
        if (findCollector) return false;

        if (val && val.length >= 3) {
          if (this.timerCollector) clearTimeout(this.timerCollector);

          this.timerClients = setTimeout(() => {
            const type = "collector"
            this.getClients(val, type);
          }, 500);
        } else {
          this.collectorList = [];
        }
      },
    },
    methods: {
      getClient(id, type) {
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/clients/${id}`;

        axios
          .get(url)
          .then((response) => {
            const item = response.data;
            if (item) {
              item.name = `${item.name} (${item.phone})`;
              if(type == "client"){
                this.clientsList = [item];
              } else {
                this.collectorList = [item];
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getUser(id) {
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/users/${id}`;

        axios
          .get(url)
          .then((response) => {
            const item = response.data;
            if (item) {
              item.name = `${item.name} (${item.login})`;
                this.userList = [item];
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getClients(searchVal, type) {
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/clients`;

        axios
          .get(url, {
            params: {
              name_or_phone: searchVal,
              //page_limit: 10,
            },
          })
          .then((response) => {
            if (type == "client") {
              this.clientsList = response.data.map((item) => {
                return {
                  name: `${item.name} (${item.phone})`,
                  id: item.id,
                };
              });
            } else {
              this.collectorList = response.data.map((item) => {
                return {
                  name: `${item.name} (${item.phone})`,
                  id: item.id,
                };
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getUsers(searchVal) {
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/users`;

        axios
          .get(url, {
            params: {
              name_or_login: searchVal,
              //page_limit: 10,
            },
          })
          .then((response) => {
            this.userList = response.data.map((item) => {
              return {
                name: `${item.name} (${item.login})`,
                id: item.id,
              };
            });
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getStoreList() {
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/store`;
        axios
            .get(url)
            .then((response) => {
            this.storeList = response.data;
            })
            .catch((error) => {
            console.log(error);
            });
      },
      getItem() {
        this.editedItem = {
          user: this.item.user,
          password: this.item.password,
          db: this.item.db,
          host: this.item.host,
          store_id: this.item.store_id,
          active: this.item.active,
          client_id: this.item.client_id,
          user_id: this.item.user_id,
          collector_id: this.item.collector_id,
          ip_address: this.item.ip_address,
          cron_is_active: this.item.cron_is_active,
        };
      },
      cancel() {
        this.editedItem = {};
        this.createdSuccess = false;
        this.$emit('cancel');
      },
      submitForm() {
        const validate = this.$refs.form.validate();
        if (validate) {
          const propsItem = Object.assign({}, this.editedItem);
          const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/setting-remote/${this.item.id}`;
  
          axios
            .post(url, propsItem)
            .then(() => {
              this.createdSuccess = true;
  
              setTimeout(() => {
                this.$emit('cancel');
              }, 1000);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      },
    },
    mounted() {
      this.getItem();
      this.getStoreList();
      if (this.editedItem.client_id) {
        const type = "client"
        this.getClient(this.editedItem.client_id, type);
      }
      if (this.editedItem.collector_id) {
        const type = "collector"
        this.getClient(this.editedItem.collector_id, type);
      }
      if (this.editedItem.user_id) {
        this.getUser(this.editedItem.user_id);
      }
    },
  };
  </script>
  