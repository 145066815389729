<template>
    <v-card>
      <v-alert
        :value="createdSuccess"
        type="success"
        class="my-0"
      >Магазин добавлен</v-alert>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-title
          class="px-4"
        >
          <span class="headline">Добавление сопоставления</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          class="px-4"
        >
          <v-select
            label="Список магазинов"
            :items="storeList"
            :rules="[v => !!v || 'Заполните поле']"
            item-text="name"
            item-value="id"
            v-model="editedItem.store_id"
            hide-details
            class="mb-4"
          ></v-select>
            <p class="mb-0">mapping Field</p>
            <v-layout
                row
                wrap
                v-for="(mapping, index) in editedItem.mapping_field"
                :key="'mapping-' + index"
                align-center
            >
                <v-flex xs5 class="pr-3">
                    <v-select
                        label="Поле заказа"
                        :item-disabled="item => item.disabled"
                        :items="availableOrderFields"
                        :rules="[v => !!v || 'Заполните поле']"
                        item-text="name"
                        item-value="id"
                        v-model="mapping.go"
                        hide-details
                        class="mb-4"
                    ></v-select>
                </v-flex>
                <v-flex xs5 class="pr-3">
                    <v-select
                        label="B24"
                        :item-disabled="item => item.disabled"
                        :items="availableB24Fields"
                        :rules="[v => !!v || 'Заполните поле']"
                        item-text="name"
                        item-value="id"
                        v-model="mapping.b24"
                        hide-details
                        class="mb-4"
                    ></v-select>
                   
                </v-flex>
                <v-flex xs1>
                <v-icon @click="mappingDelete(index)">
                    delete
                </v-icon>
                </v-flex>
            </v-layout>
            <v-icon @click="mappingAdd()">
                add
            </v-icon>
        </v-card-text>
        <v-card-actions
          class="px-4 pb-4"
        >
          <v-btn
            @click.native="cancel()"
          >Отмена</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            @click="submitForm"
          >Сохранить</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            editedItem: {},
            createdSuccess: false,
            storeList:[],
            OrderField:[],
            B24Field:[],
        };
    },
    methods: {
      getItem() {
            this.editedItem = {
                mapping_field: JSON.parse(this.item.mapping_field),
                sync_code: this.item.sync_code,
                store_id: this.item.store_id,
            };
      },
      getOrderField() {
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/field-mapping-orders`;
        axios
            .get(url)
            .then((response) => {
                this.OrderField = response.data;
            })
            .catch((error) => {
            console.log(error);
            });
      },
      getB24Field() {
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/field-mapping-b24`;
        axios
            .get(url)
            .then((response) => {
                this.B24Field = response.data;
            })
            .catch((error) => {
            console.log(error);
            });
      },
      mappingAdd() {
        this.editedItem.mapping_field.push({
            go: null,
            b24: null,
        });
      },
      mappingDelete(index) {
        this.editedItem.mapping_field.splice(index, 1);
      },
      getStoreList() {
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/store`;
        axios
            .get(url)
            .then((response) => {
            this.storeList = response.data;
            })
            .catch((error) => {
            console.log(error);
            });
      },
      cancel() {
        this.editedItem = {};
        this.createdSuccess = false;
        this.$emit('cancel');
      },
      submitForm() {
        const validate = this.$refs.form.validate();
        if (validate) {
          const propsItem = Object.assign({}, this.editedItem);
          const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/field-mapping/${this.item.id}`;
          propsItem.mapping_field = JSON.stringify(propsItem.mapping_field)
          axios
            .post(url, propsItem)
            .then(() => {
              this.createdSuccess = true;
  
              setTimeout(() => {
                this.$emit('cancel');
              }, 1000);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      },
    },
    mounted() {
        this.getItem();
        this.getStoreList();
        this.getOrderField();
        this.getB24Field();
    },
    computed: {
        availableOrderFields() {
            const selectedFields = this.editedItem.mapping_field.map(mapping => mapping.go);
            return this.OrderField.map(field => ({
            name: field,
            id: field,
            disabled: selectedFields.includes(field)
            }));
        },
        availableB24Fields() {
            const selectedFields = this.editedItem.mapping_field.map(mapping => mapping.b24);
            return this.B24Field.map(field => ({
            name: field,
            id: field,
            disabled: selectedFields.includes(field)
            }));
        }
    },
  };
  </script>
  