<template>
  <v-card>
    <v-alert
      :value="createdSuccess"
      type="success"
      class="my-0"
    >Группа добавлена</v-alert>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card-title
        class="px-4"
      >
        <span class="headline">Добавление группы</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="px-4"
      >
        <v-text-field
          label="Имя"
          :rules="[v => !!v || 'Заполните поле']"
          v-model="editedItem.name"
        ></v-text-field>
        <v-text-field
          label="Код"
          :rules="[v => !!v || 'Заполните поле']"
          v-model="editedItem.code"
        ></v-text-field>
        <v-select
          label="Page"
          :items="pageList"
          :rules="[v => v.length > 0 || 'Заполните поле']"
          item-text="name"
          item-value="id"
          v-model="editedItem.page_ids"
          hide-details
          class="mb-4"
          chips
          multiple
        ></v-select>
        <v-checkbox
          v-model="editedItem.system"
          label="active"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions
        class="px-4 pb-4"
      >
        <v-btn
          @click.native="cancel()"
        >Отмена</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          @click="submitForm"
        >Сохранить</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    pageList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      editedItem: {},
      createdSuccess: false,
    };
  },
  methods: {
    getItem() {
      this.editedItem = {
        name: this.item.name,
        code: this.item.code,
        page_ids: this.item.page_ids,
        system: this.item.system,
      };
    },
    cancel() {
      this.editedItem = {};
      this.createdSuccess = false;
      this.$emit('cancel');
    },
    submitForm() {
      const validate = this.$refs.form.validate();
      if (validate) {
        const propsItem = Object.assign({}, this.editedItem);
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/groups/${this.item.id}`;

        axios
          .post(url, propsItem)
          .then(() => {
            this.createdSuccess = true;

            setTimeout(() => {
              this.$emit('cancel');
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    this.getItem();
  },
};
</script>
