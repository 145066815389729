<template>
    <v-container
      fluid
      class="pa-0"
    >
      <v-dialog
        :value="loadingDialog"
        persistent
        max-width="320px"
      >
        <v-list>
          <v-list-tile
            v-for="(item, index) in loadingData"
            :key="index"
            avatar
            :color="(item.error) ? 'red' : item.color"
          >
            <v-list-tile-avatar>
              <v-progress-circular
                :value="100"
                :size="30"
                :color="(item.error) ? 'red' : item.color"
                :indeterminate="item.loading"
              ></v-progress-circular>
            </v-list-tile-avatar>
  
            <v-list-tile-content>
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-dialog>
      <template v-if="!loadingDialog">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              prepend-icon="search"
              label="Поиск"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
  
            <v-dialog
              v-model="dialogForm"
              persistent
              max-width="420px"
            >
              <v-btn slot="activator" color="primary" dark class="mb-2">Добавить</v-btn>
              <template
                v-if="dialogForm"
              >
                <mapping-edit
                  v-if="Object.keys(this.editedItem).length > 0"
                  :item="editedItem"
                  @cancel="closeDialog()"
                ></mapping-edit>
                <mapping-add
                  v-else
                  @cancel="closeDialog()"
                ></mapping-add>
              </template>
            </v-dialog>
          </v-card-title>
  
          <v-data-table
            :headers="headersTable"
            :items="useMappingList"
            hide-actions
            no-data-text="Пользователей не найдено"
            no-results-text="Пользователей не найдено"
            :search="search"
            :pagination.sync="pagination"
          >
            <template slot="items" slot-scope="props">
              <td class="text-xs-right" style="width: 30px;">{{ props.item.id }}</td>
              <td v-if="props.item.mapping_field">
                <table>
                    <thead>
                    <tr>
                        <th>go</th>
                        <th>b24</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(mapping, index) in parseMappingField(props.item.mapping_field)" :key="index">
                        <td>{{ mapping.go }}</td>
                        <td>{{ mapping.b24 }}</td>
                    </tr>
                    </tbody>
                </table>
               </td>
               <td v-else></td>
              <td>{{ props.item.sync_code }}</td>
              <td>{{ (store = storeList.find((item) => item.id === props.item.store_id)) ? store.name : '' }}</td>
              <td class="text-xs-right" style="width: 140px">
                <v-icon
                  @click="editItem(props.item)"
                  title="Изменить"
                >
                  edit
                </v-icon>
              </td>
            </template>
          </v-data-table>
  
          <v-layout row wrap justify-space-around class="py-2">
            <v-flex xs2 class="px-3">
              <v-text-field
                label="Количество на странице"
                v-model.number="take"
                hide-details
                @change="changeShowElem()"
              ></v-text-field>
            </v-flex>
            <v-flex xs10 class="text-xs-right px-3">
              <v-btn
                small
                color="info"
                class="ml-3"
                :disabled="page === 1"
                @click="prevPage()"
              >
                <v-icon dark>keyboard_arrow_left</v-icon>
              </v-btn>
              <v-btn
                small
                color="info"
                class="ml-3"
                :disabled="useMappingList.length < take"
                @click="nextPage()"
              >
                <v-icon dark>keyboard_arrow_right</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </template>
    </v-container>
  </template>
  
  <script>
  import axios from 'axios';
  import MappingEdit from './edit.vue';
  import MappingAdd from './add.vue';
  
  export default {
    name: 'Users',
    components: {
      MappingEdit,
      MappingAdd,
    },
    data() {
      return {
        loadingData: [
          {
            title: 'Получение поля',
            error: false,
            loading: true,
            color: 'deep-orange',
            id: 'field-mapping',
          },
        ],
        search: '',
        filter: {},
        headersTable: [
          {
            text: 'Go',
            align: 'left',
            value: 'go',
            sortable: false,
          },
          {
            text: 'B24',
            align: 'left',
            value: 'b24',
            sortable: false,
          },
          {
            text: 'Store',
            align: 'left',
            value: 'store',
            sortable: false,
          },
          {
            text: '',
            align: 'right',
            sortable: false,
            value: 'action',
          },
        ],
        dialogForm: false,
        useMappingList: [],
        editedItem: {},
        pagination: {
          sortBy: "id",
          rowsPerPage: -1,
          descending: true,
        },
        storeList:[],
        take: 20,
        page: 1,
      };
    },
    watch: {
      pagination: {
        handler() {
          this.page = 1;
  
          this.getSettingList();
        },
        deep: true,
      },
    },
    computed: {
      loadingDialog: function loadingDialog() {
        const loadData = this.loadingData.filter(item => !item.error && !item.loading);
        return (loadData.length === this.loadingData.length) ? 0 : 1;
      },
    },
    methods: {
      parseMappingField(mappingField) {
        try {
            return JSON.parse(mappingField);
        } catch (error) {
            console.error('Error parsing mapping field:', error);
            return [];
        }
      },
      getStoreList() {
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/store`;
        axios
            .get(url)
            .then((response) => {
            this.storeList = response.data;
            })
            .catch((error) => {
            console.log(error);
            });
      },
      getSettingList() {
        const loadData = this.loadingData.find(item => item.id === 'field-mapping');
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/field-mapping`;
  
        const propsItem = {
          page: this.page,
          page_limit: this.take,
        };
  
        Object.keys(this.filter).forEach((key) => {
          if (this.filter[key]) {
            propsItem[key] = this.filter[key];
          }
        });
  
        axios
          .get(url, {
            params: propsItem,
          })
          .then((response) => {
            const items = response.data;
            this.useMappingList = items;
            loadData.title = 'Пользователи получены!';
            loadData.loading = false;
          })
          .catch((error) => {
            loadData.title = 'Ошибка получения пользователей!';
            loadData.error = true;
            console.log('error', error);
          });
      },
      changeShowElem() {
        localStorage.setItem("countElemPage", this.take);
        this.$store.commit("setCountElemPage", this.take);
        this.page = 1;
        this.getSettingList();
      },
      prevPage() {
        this.page -= 1;
        this.getSettingList();
      },
      nextPage() {
        this.page += 1;
        this.getSettingList();
      },
      closeDialog() {
        this.getSettingList();
        this.dialogForm = false;
        this.editedItem = {};
      },
      editItem(item) {
        this.editedItem = item;
        this.dialogForm = true;
      },
    },
    mounted() {
      this.take = this.$store.getters.getCountElemPage;
  
      this.getSettingList();
      this.getStoreList();
    },
  };
  </script>
  
  <style lang="scss" scoped>
    .v-table {
  
      tr:nth-child(even) {
  
        td {
          background: #f9f9f9;
        }
  
        &:hover {
  
          td {
            background: #eee;
          }
        }
      }
    }
  </style>
  