<template>
    <v-card>
      <v-alert
        :value="createdSuccess"
        type="success"
        class="my-0"
      >Магазин добавлен</v-alert>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-title
          class="px-4"
        >
          <span class="headline">Добавление настройки</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          class="px-4"
        >
          test
        </v-card-text>
        <v-card-actions
          class="px-4 pb-4"
        >
          <v-btn
            @click.native="cancel()"
          >Отмена</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            @click="submitForm"
          >Сохранить</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        
      };
    },
    methods: {
      cancel() {
        this.createdSuccess = false;
        this.$emit('cancel');
      },
      submitForm() {
       
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/bit-order-reset`;
        axios
          .post(url)
          .then(() => {
            this.createdSuccess = true;

            setTimeout(() => {
              this.$emit('cancel');
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
  };
  </script>
  