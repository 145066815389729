<template>
    <v-container
      fluid
      class="pa-0"
    >
    <template>
      <template v-if="settingShow">
        <p class="title mb-4 mt-4">Setting remote</p>
          <v-dialog
            :value="loadingDialog"
            persistent
            max-width="320px"
          >
            <v-list>
              <v-list-tile
                v-for="(item, index) in loadingData"
                :key="index"
                avatar
                :color="(item.error) ? 'red' : item.color"
              >
                <v-list-tile-avatar>
                  <v-progress-circular
                    :value="100"
                    :size="30"
                    :color="(item.error) ? 'red' : item.color"
                    :indeterminate="item.loading"
                  ></v-progress-circular>
                </v-list-tile-avatar>
      
                <v-list-tile-content>
                  <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-dialog>
          <template v-if="!loadingDialog">
            <v-card>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  prepend-icon="search"
                  label="Поиск"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  
                  :loading="btnLoad"
                  @click="resetOrder"
                  >Перезагрузить</v-btn
                >
                <v-dialog
                  v-model="dialogForm"
                  persistent
                  max-width="420px"
                >
                  <v-btn slot="activator" color="primary" dark class="mb-2">Добавить</v-btn>
                  <template
                    v-if="dialogForm"
                  >
                    <setting-edit
                      v-if="Object.keys(this.editedItem).length > 0"
                      :item="editedItem"
                      @cancel="closeDialog()"
                    ></setting-edit>
                    <setting-add
                      v-else
                      @cancel="closeDialog()"
                    ></setting-add>
                  </template>
                </v-dialog>
              </v-card-title>
      
              <v-data-table
                :headers="headersTable"
                :items="useSettingList"
                hide-actions
                no-data-text="Пользователей не найдено"
                no-results-text="Пользователей не найдено"
                :search="search"
                :pagination.sync="pagination"
              >
                <template slot="items" slot-scope="props">
                  <td class="text-xs-right" style="width: 30px;">{{ props.item.id }}</td>
                  <td>{{ props.item.user }}</td>
                  <td>{{ props.item.db }}</td>
                  <td>{{ props.item.password }}</td>
                  <td>{{ props.item.host }}</td>
                  <td>{{ (store = storeList.find((item) => item.id === props.item.store_id)) ? store.name : '' }}</td>
                  <td class="text-xs-right" style="width: 140px">
                    <v-icon
                      @click="editItem(props.item)"
                      title="Изменить"
                    >
                      edit
                    </v-icon>
                  </td>
                </template>
              </v-data-table>
      
              <v-layout row wrap justify-space-around class="py-2">
                <v-flex xs2 class="px-3">
                  <v-text-field
                    label="Количество на странице"
                    v-model.number="take"
                    hide-details
                    @change="changeShowElem()"
                  ></v-text-field>
                </v-flex>
                <v-flex xs10 class="text-xs-right px-3">
                  <v-btn
                    small
                    color="info"
                    class="ml-3"
                    :disabled="page === 1"
                    @click="prevPage()"
                  >
                    <v-icon dark>keyboard_arrow_left</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    color="info"
                    class="ml-3"
                    :disabled="useSettingList.length < take"
                    @click="nextPage()"
                  >
                    <v-icon dark>keyboard_arrow_right</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card>
          </template>
      </template>
      <template v-else>
        <v-btn @click.native="getSettingList()">Setting remote</v-btn>
      </template>
    </template>
    <template >
      <template v-if="fieldMappingShow">
        <p class="title mb-4 mt-4">Mapping Field</p>
        <field-mapping></field-mapping>
      </template>
      <template v-else>
        <v-btn @click.native="setFieldMap()">Mapping Field</v-btn>
      </template>
    </template>
    <template >
      <template v-if="pageShow">
        <p class="title mb-4 mt-4">Page List</p>
        <page></page>
      </template>
      <template v-else>
        <v-btn @click.native="setPage()">Page List</v-btn>
      </template>
    </template>
    <template >
      <template v-if="userGroupShow">
        <p class="title mb-4 mt-4">User Group</p>
        <user-groups></user-groups>
      </template>
      <template v-else>
        <v-btn @click.native="setUserGroup()">User Group List</v-btn>
      </template>
    </template>
    <template >
      <template v-if="itemOrderShow">
        <p class="title mb-4 mt-4">Item Order</p>
        <item-order></item-order>
      </template>
      <template v-else>
        <v-btn @click.native="setItemOrder()">Item Order List</v-btn>
      </template>
    </template>
    </v-container>
  </template>
  
  <script>
  import axios from 'axios';
  import SettingEdit from './edit.vue';
  import SettingAdd from './add.vue';
  import ResetOrder from './reset.vue';
  import FieldMapping from '../FieldMapping/List.vue';
  import Page from '../Page/List.vue';
  import UserGroups from '../UsersGroups/List.vue';
  import ItemOrder from '../ItemOrder/List.vue';
  
  export default {
    name: 'Users',
    components: {
      SettingEdit,
      SettingAdd,
      ResetOrder,
      FieldMapping,
      Page,
      UserGroups,
      ItemOrder,
    },
    data() {
      return {
        loadingData: [
          {
            title: 'Получение магазина',
            error: false,
            loading: true,
            color: 'deep-orange',
            id: 'setting-remote',
          },
        ],
        search: '',
        filter: {},
        headersTable: [
          {
            text: 'ID',
            align: 'right',
            value: 'id',
            sortable: true,
          },
          {
            text: 'пользователь',
            align: 'left',
            value: 'user',
            sortable: true,
          },
          {
            text: 'имя базы данных',
            align: 'left',
            value: 'db',
            sortable: true,
          },
          {
            text: 'пароль',
            align: 'left',
            value: 'password',
            sortable: true,
          },
          {
            text: 'путь',
            align: 'left',
            value: 'path',
            sortable: true,
          },
          {
            text: 'магазин',
            align: 'left',
            value: 'path',
            sortable: true,
          },
          {
            text: '',
            align: 'right',
            sortable: false,
            value: 'action',
          },
        ],
        dialogForm: false,
        useSettingList: [],
        editedItem: {},
        pagination: {
          sortBy: "id",
          rowsPerPage: -1,
          descending: true,
        },
        settingShow:false,
        fieldMappingShow:false,
        pageShow:false,
        userGroupShow:false,
        itemOrderShow:false,
        btnLoad: false,
        storeList:[],
        take: 20,
        page: 1,
      };
    },
    watch: {
      pagination: {
        handler() {
          this.page = 1;
  
          this.getSettingList();
        },
        deep: true,
      },
    },
    computed: {
      loadingDialog: function loadingDialog() {
        const loadData = this.loadingData.filter(item => !item.error && !item.loading);
        return (loadData.length === this.loadingData.length) ? 0 : 1;
      },
    },
    methods: {
      setFieldMap(){
        this.fieldMappingShow = true
      },
      setPage(){
        this.pageShow = true
      },
      setUserGroup(){
        this.userGroupShow = true
      },
      setItemOrder(){
        this.itemOrderShow = true
      },
      getStoreList() {
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/store`;
        axios
            .get(url)
            .then((response) => {
            this.storeList = response.data;
            })
            .catch((error) => {
            console.log(error);
            });
      },
      getSettingList() {
        const loadData = this.loadingData.find(item => item.id === 'setting-remote');
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/setting-remote`;
  
        const propsItem = {
          page: this.page,
          page_limit: this.take,
        };
  
        Object.keys(this.filter).forEach((key) => {
          if (this.filter[key]) {
            propsItem[key] = this.filter[key];
          }
        });
  
        axios
          .get(url, {
            params: propsItem,
          })
          .then((response) => {
            const items = response.data;
            this.useSettingList = items;
            loadData.title = 'Пользователи получены!';
            loadData.loading = false;
            this.settingShow = true;
          })
          .catch((error) => {
            loadData.title = 'Ошибка получения пользователей!';
            loadData.error = true;
            console.log('error', error);
          });
      },
      changeShowElem() {
        localStorage.setItem("countElemPage", this.take);
        this.$store.commit("setCountElemPage", this.take);
        this.page = 1;
        this.getSettingList();
      },
      prevPage() {
        this.page -= 1;
        this.getSettingList();
      },
      nextPage() {
        this.page += 1;
        this.getSettingList();
      },
      closeDialog() {
        this.getSettingList();
        this.dialogForm = false;
        this.editedItem = {};
      },
      editItem(item) {
        this.editedItem = item;
        this.dialogForm = true;
      },
      resetOrder() {
       this.btnLoad = true;
       const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/bit-order-reset`;
       axios
         .get(url)
         .then(() => {

           setTimeout(() => {
             this.btnLoad = false;
           }, 1000);
         })
         .catch((error) => {
           console.log(error);
         });
     },
    },
    mounted() {
      this.take = this.$store.getters.getCountElemPage;
  
      //this.getSettingList();
      this.getStoreList();
    },
  };
  </script>
  
  <style lang="scss" scoped>
    .v-table {
  
      tr:nth-child(even) {
  
        td {
          background: #f9f9f9;
        }
  
        &:hover {
  
          td {
            background: #eee;
          }
        }
      }
    }
  </style>
  