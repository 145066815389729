<template>
    <v-card>
      <v-alert
        :value="createdSuccess"
        type="success"
        class="my-0"
      >Магазин добавлен</v-alert>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-title
          class="px-4"
        >
          <span class="headline">Добавление сопоставления</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          class="px-4"
        >
          <v-select
            label="Список магазинов"
            :items="storeList"
            :rules="[v => !!v || 'Заполните поле']"
            item-text="name"
            item-value="id"
            v-model="editedItem.store_id"
            hide-details
            class="mb-4"
          ></v-select>
            <p class="mb-0">mapping Field</p>
            <v-layout
                row
                wrap
                v-for="(mapping, index) in editedItem.mapping_field"
                :key="'mapping-' + index"
                align-center
            >
                <v-flex xs5 class="pr-3">
                    <v-select
                        label="Поле заказа"
                        :items="availableOrderFields"
                        :item-disabled="item => item.disabled"
                        :rules="[v => !!v || 'Заполните поле']"
                        item-text="name"
                        item-value="id"
                        v-model="mapping.go"
                        hide-details
                        class="mb-4"
                    ></v-select>
                </v-flex>
                <v-flex xs5 class="pr-3">
                    <v-select
                        label="B24"
                        :items="availableB24Fields"
                        :item-disabled="item => item.disabled"
                        :rules="[v => !!v || 'Заполните поле']"
                        item-text="name"
                        item-value="id"
                        v-model="mapping.b24"
                        hide-details
                        class="mb-4"
                    ></v-select>
                   
                </v-flex>
                <v-flex xs1>
                <v-icon @click="mappingDelete(index)">
                    delete
                </v-icon>
                </v-flex>
            </v-layout>
            <v-icon @click="mappingAdd()">
                add
            </v-icon>
        </v-card-text>
        <v-card-actions
          class="px-4 pb-4"
        >
          <v-btn
            @click.native="cancel()"
          >Отмена</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            @click="submitForm"
          >Сохранить</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        editedItem: {
            store_id : 0,
            mapping_field: [
                {
                    go: '',
                    b24: '',
                },
            ],
            sync_code: 'orders'
        },
        storeList:[],
        OrderField:[],
        B24Field:[],
        createdSuccess: false,
      };
    },
    methods: {
      getOrderField() {
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/field-mapping-orders`;
        axios
            .get(url)
            .then((response) => {
                this.OrderField = response.data;
            })
            .catch((error) => {
            console.log(error);
            });
      },
      getB24Field() {
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/field-mapping-b24`;
        axios
            .get(url)
            .then((response) => {
                this.B24Field = response.data;
            })
            .catch((error) => {
            console.log(error);
            });
      },
    //   async getOrderField() {
    //     try {
    //         const url = '/field-mapping-orders';
    //         const response = await axios.get(url);
    //         this.orderFields = response.data; // Assign the fetched data to the orderFields array
    //         console.log(this.orderFields); // Log the data here to ensure it's fetched and assigned correctly
    //     } catch (error) {
    //         console.error('Error fetching order fields:', error);
    //     }
    //   },
    //   async getB24Field() {
    //     try {
    //         const url = '/field-mapping-b24';
    //         const response = await axios.get(url);
    //         this.b24Fields = response.data;
    //         console.log(this.b24Fields);
    //     } catch (error) {
    //         console.error('Error fetching B24 fields:', error);
    //     }
    //   },
      mappingAdd() {
        this.editedItem.mapping_field.push({
            go: '',
            b24: '',
        });
      },
      mappingDelete(index) {
        this.editedItem.mapping_field.splice(index, 1);
      },
      getStoreList() {
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/store`;
        axios
            .get(url)
            .then((response) => {
            this.storeList = response.data;
            })
            .catch((error) => {
            console.log(error);
            });
      },
      cancel() {
        this.editedItem = {};
        this.createdSuccess = false;
        this.$emit('cancel');
      },
      submitForm() {
        const validate = this.$refs.form.validate();
        if (validate) {
          const propsItem = Object.assign({}, this.editedItem);
          const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/field-mapping`;
          propsItem.mapping_field = JSON.stringify(propsItem.mapping_field)
          axios
            .post(url, propsItem)
            .then(() => {
              this.createdSuccess = true;
  
              setTimeout(() => {
                this.$emit('cancel');
              }, 1000);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      },
    },
    mounted() {
        this.getStoreList();
        this.getOrderField();
        this.getB24Field();
    },
    computed: {
        availableOrderFields() {
            const selectedFields = this.editedItem.mapping_field.map(mapping => mapping.go);
            return this.OrderField.map(field => ({
            name: field,
            id: field,
            disabled: selectedFields.includes(field)
            }));
        },
        availableB24Fields() {
            const selectedFields = this.editedItem.mapping_field.map(mapping => mapping.b24);
            return this.B24Field.map(field => ({
            name: field,
            id: field,
            disabled: selectedFields.includes(field)
            }));
        }
    },
  };
  </script>
  