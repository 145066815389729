<template>
    
    <v-container
      fluid
      class="pa-0"
    >
        <div class="list-group">
            <v-btn @click="submitUpdatedOrder" color="primary" dark class="mb-2">Update</v-btn>
            <draggable
            :list="paymentTypesList"
            >
            <div
                class="list-group-item"
                v-for="(element, index) in paymentTypesList"
                :key="element.name"
            >
                <span class="order mr-2">{{ index + 1 }}.</span>
                <span>{{ element.name }}</span>
            </div>
            </draggable>
        </div>
    </v-container>
  </template>
  
  <script>
  import draggable from "vuedraggable";
  import axios from 'axios';
  export default {
    name: "simple",
    display: "Simple",
    order: 1,
    components: {
      draggable
    },
    data() {
      return {
        enabled: true,
        paymentTypesList: [],
      };
    },
    methods: {
        getPaymentTypesList() {
            const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payment-types`;
            // test
            axios
                .get(url)
                    .then((response) => {
                    const items = response.data;
                    this.paymentTypesList = items;
                    console.log(this.paymentTypesList);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        submitUpdatedOrder() {
        // Map the updated list to the desired format
        const updatedOrder = this.paymentTypesList.map((item, index) => ({
            id: item.id,
            sort: index + 1,
            name:item.name,
        }));

        console.log("Updated Order:", updatedOrder);

        // Send the updated order to the API
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payment-types-sort`;

        axios
            .post(url, updatedOrder)
            .then((response) => {
            console.log("Order updated successfully:", response.data);
            })
            .catch((error) => {
            console.error("Error updating order:", error);
            });
        },

    },
    created() {
        this.getPaymentTypesList();
    },
  };
  </script>
  <style scoped>
    .list-group {
        width: 90%;
        margin:auto;   
    }
    .list-group-item {
        position: relative;
        display: block;
        padding: .75rem 1.25rem;
        margin-bottom: -1px;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, .125);
        cursor: move;
        margin-top:10px;
    }
    .order {
        color:blue;
    }
  
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  </style>