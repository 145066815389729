<template>
    <v-container
      fluid
      class="pa-0"
    >
      <v-dialog
        :value="loadingDialog"
        persistent
        max-width="320px"
      >
        <v-list>
          <v-list-tile
            v-for="(item, index) in loadingData"
            :key="index"
            avatar
            :color="(item.error) ? 'red' : item.color"
          >
            <v-list-tile-avatar>
              <v-progress-circular
                :value="100"
                :size="30"
                :color="(item.error) ? 'red' : item.color"
                :indeterminate="item.loading"
              ></v-progress-circular>
            </v-list-tile-avatar>
  
            <v-list-tile-content>
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-dialog>
      <template v-if="!loadingDialog">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              prepend-icon="search"
              label="Поиск"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
  
            <v-dialog
              v-model="dialogForm"
              persistent
              max-width="420px"
            >
              <v-btn slot="activator" color="primary" dark class="mb-2">Добавить</v-btn>
              <template
                v-if="dialogForm"
              >
                <page-edit
                  v-if="Object.keys(this.editedItem).length > 0"
                  :item="editedItem"
                  @cancel="closeDialog()"
                ></page-edit>
                <page-add
                  v-else
                  @cancel="closeDialog()"
                ></page-add>
              </template>
            </v-dialog>
          </v-card-title>
  
          <v-data-table
            :headers="headersTable"
            :items="pageList"
            hide-actions
            no-data-text="Пользователей не найдено"
            no-results-text="Пользователей не найдено"
            :search="search"
            :pagination.sync="pagination"
          >
            <template slot="items" slot-scope="props">
              <td class="text-xs-right" style="width: 30px;">{{ props.item.id }}</td>
              <td class="text-xs-right">{{ props.item.name }}</td>
              <td class="text-xs-right">{{ props.item.url }}</td>
              <td class="text-xs-right">{{ props.item.active }}</td>
              <td class="text-xs-right" style="width: 140px">
                <v-icon
                  @click="editItem(props.item)"
                  title="Изменить"
                >
                  edit
                </v-icon>
              </td>
            </template>
          </v-data-table>
  
          <v-layout row wrap justify-space-around class="py-2">
            <v-flex xs2 class="px-3">
              <v-text-field
                label="Количество на странице"
                v-model.number="take"
                hide-details
                @change="changeShowElem()"
              ></v-text-field>
            </v-flex>
            <v-flex xs10 class="text-xs-right px-3">
              <v-btn
                small
                color="info"
                class="ml-3"
                :disabled="page === 1"
                @click="prevPage()"
              >
                <v-icon dark>keyboard_arrow_left</v-icon>
              </v-btn>
              <v-btn
                small
                color="info"
                class="ml-3"
                :disabled="pageList.length < take"
                @click="nextPage()"
              >
                <v-icon dark>keyboard_arrow_right</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </template>
    </v-container>
  </template>
  
  <script>
  import axios from 'axios';
  import PageEdit from './edit.vue';
  import PageAdd from './add.vue';
  
  export default {
    name: 'Pages',
    components: {
      PageEdit,
      PageAdd,
    },
    data() {
      return {
        loadingData: [
          {
            title: 'Получение магазина',
            error: false,
            loading: true,
            color: 'deep-orange',
            id: 'page',
          },
        ],
        search: '',
        filter: {},
        headersTable: [
          {
            text: 'ID',
            align: 'right',
            value: 'id',
            sortable: true,
          },
          {
            text: 'Пользователь',
            align: 'right',
            value: 'name',
            sortable: true,
          },
          {
            text: 'Url',
            align: 'right',
            sortable: true,
            value: 'url',
          },
          {
            text: 'active',
            align: 'right',
            sortable: true,
            value: 'active',
          },
          {
            text: '',
            align: 'right',
            sortable: true,
            value: 'action',
          },
        ],
        dialogForm: false,
        pageList: [],
        editedItem: {},
        pagination: {
          sortBy: "id",
          rowsPerPage: -1,
          descending: true,
        },
        take: 20,
        page: 1,
      };
    },
    watch: {
      pagination: {
        handler() {
          this.page = 1;
  
          this.getPageList();
        },
        deep: true,
      },
    },
    computed: {
      loadingDialog: function loadingDialog() {
        const loadData = this.loadingData.filter(item => !item.error && !item.loading);
        return (loadData.length === this.loadingData.length) ? 0 : 1;
      },
    },
    methods: {
      getPageList() {
        const loadData = this.loadingData.find(item => item.id === 'page');
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/pages`;
  
        const propsItem = {
          page: this.page,
          page_limit: this.take,
        };
  
        Object.keys(this.filter).forEach((key) => {
          if (this.filter[key]) {
            propsItem[key] = this.filter[key];
          }
        });
  
        if (this.pagination.sortBy) {
          const sort = {};
          sort[this.pagination.sortBy] = (this.pagination.descending) ? 'desc_nulls_last' : 'asc_nulls_last';
  
          propsItem.order_by = JSON.stringify(sort);
        }
  
        axios
          .get(url, {
            params: propsItem,
          })
          .then((response) => {
            const items = response.data;
            this.pageList = items;
            loadData.title = 'Пользователи получены!';
            loadData.loading = false;
          })
          .catch((error) => {
            loadData.title = 'Ошибка получения пользователей!';
            loadData.error = true;
            console.log('error', error);
          });
      },
      changeShowElem() {
        localStorage.setItem("countElemPage", this.take);
        this.$store.commit("setCountElemPage", this.take);
        this.page = 1;
        this.getPageList();
      },
      prevPage() {
        this.page -= 1;
        this.getPageList();
      },
      nextPage() {
        this.page += 1;
        this.getPageList();
      },
      closeDialog() {
        this.getPageList();
        this.dialogForm = false;
        this.editedItem = {};
      },
      editItem(item) {
        this.editedItem = item;
        this.dialogForm = true;
      },
    },
    mounted() {
      this.take = this.$store.getters.getCountElemPage;
  
      this.getPageList();
    },
  };
  </script>
  
  <style lang="scss" scoped>
    .v-table {
  
      tr:nth-child(even) {
  
        td {
          background: #f9f9f9;
        }
  
        &:hover {
  
          td {
            background: #eee;
          }
        }
      }
    }
  </style>
  